

// -----------------------------------------------------------------------------------------*/
// ---- FONTS -----------------------------------------------------------------------------*/
// ---------------------------------------------------------------------------------------*/

/* Quicksand REGULAR */
@font-face {
    font-family: 'Quicksand';
    src: url('../fonts/Quicksand-Regular.woff2') format('woff2'),
        url('../fonts/Quicksand-Regular.woff') format('woff'),
        url('../fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Quicksand BOLD */
@font-face {
    font-family: 'Quicksand';
    src: url('../fonts/Quicksand-Bold.woff2') format('woff2'),
        url('../fonts/Quicksand-Bold.woff') format('woff'),
        url('../fonts/Quicksand-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


.f-no-serif { 
    font-family: 'Quicksand';
    font-display: swap;
}
  
  

// -----------------------------------------------------------------------------------------*/
// ---- FA5 -------------------------------------------------------------------------------*/
// ---------------------------------------------------------------------------------------*/
@import "fa5/fontawesome.scss";
// @import "fa5/regular.scss"; 
@import "fa5/light.scss";
@import "fa5/solid.scss";
// @import "fa5/duotone.scss"; 



// -----------------------------------------------------------------------------------------*/
// ---- ICOMOON ---------------------------------------------------------------------------*/
// ---------------------------------------------------------------------------------------*/
@import "icomoon.scss";